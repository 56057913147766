<template>
  <div>
    <CContainer>
      <CRow>
        <CCol md="6">
          <CCardGroup>
            <CCard>
              <CCardHeader>
                <h3 v-if="this.rabbitAdviceData.id">
                  Редактирование совета кролика
                </h3>
                <h3 v-else>Создание совета кролика</h3>
              </CCardHeader>
              <CCardBody>
                <CForm @submit.prevent="handleSubmit">
                  <CInput v-model="rabbitAdviceData.lead" label="Заголовок" placeholder=""
                    @input="requestRabbitAdviceUpdate">
                  </CInput>
                  <CTextarea v-model="rabbitAdviceData.text" label="Содержание" placeholder="" rows="10"
                    @input="requestRabbitAdviceUpdate" />
                  <CInput v-model="rabbitAdviceData.order" label="Порядковый номер дня" placeholder=""
                    @input="requestRabbitAdviceUpdate" type="number">
                  </CInput>
                  <br>
                  <CInputFile label="Картинка для вопроса" placeholder="" @change="uploadRabbitAdviceImage" />
                  <div v-if="rabbitAdviceData.image">
                    <img style="max-width: 400px" :src="rabbitAdviceData.image" />
                  </div>
                  <br>
                  <CRow>
                    <CCol>
                      <CButton v-if="this.rabbitAdviceData.id" color="success" type="submit">
                        Сохранить
                      </CButton>
                      <CButton v-else color="success" type="submit">
                        Создать
                      </CButton>
                    </CCol>
                    <CButton v-if="rabbitAdviceData.id" color="success" type="button" @click="
                      router.push({
                        name: 'rabbit-advice-create',
                      })
                    ">
                      Добавить ещё
                    </CButton>
                  </CRow>
                </CForm>
              </CCardBody>
            </CCard>
          </CCardGroup>
        </CCol>
      </CRow>
    </CContainer>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { makeCoreUICSelectList, updateViewURL } from "../helpers";
import { debounce } from "lodash";
import { router } from "../router";
export default {
  name: "rabbitAdviceForm",
  data() {
    return {
      router,
      rabbitAdviceData: {
        id: this.$route.params.id,
        lead: null,
        text: null,
        order: null,
        image: null,
      },
    };
  },

  created() {
    this.storeSubscription = this.$store.subscribe((mutation) => {
      if (mutation.type === "rabbitAdvices/getByIdSuccess") {
        this.initRabbitAdviceForm(mutation.payload);
      } else if (mutation.type === "rabbitAdvices/createSuccess") {
        this.initRabbitAdviceForm(mutation.payload);
        updateViewURL(router, {
          name: "rabbit-advice-edit",
          params: { id: this.rabbitAdviceData.id },
        });
      }
    });
    if (this.rabbitAdviceData.id) {
      this["rabbitAdvices/getById"](this.rabbitAdviceData.id);
    }
  },
  beforeDestroy() {
    if (this.storeSubscription) {
      this.storeSubscription();
    }
  },
  methods: {
    ...mapActions([
      "rabbitAdvices/create",
      "rabbitAdvices/update",
      "rabbitAdvices/getById",
      "rabbitAdvices/saveAdviceImage",
    ]),

    requestRabbitAdviceUpdate: debounce(function () {
      this.updaterabbitAdvice();
    }, 500),

    updaterabbitAdvice() {
      let method;
      if (this.rabbitAdviceData.id) {
        method = "rabbitAdvices/update";
      } else {
        method = "rabbitAdvices/create";
      }
      const dataToSend = Object.assign({}, this.rabbitAdviceData);
      delete dataToSend.image
      this[method](dataToSend);
    },
    initRabbitAdviceForm(data) {
      this.rabbitAdviceData.id = data.id;
      this.rabbitAdviceData.lead = data.lead;
      this.rabbitAdviceData.text = data.text;
      this.rabbitAdviceData.order = data.order;
      this.rabbitAdviceData.image = data.image;
    },
    uploadRabbitAdviceImage(files) {
      const file = files[0];
      const reader = new FileReader();
      const saveThis = this;

      reader.addEventListener(
        "load",
        function () {
          saveThis.rabbitAdviceData.image = reader.result;
          saveThis["rabbitAdvices/saveAdviceImage"]({
            id: saveThis.rabbitAdviceData.id,
            dataUrl: saveThis.rabbitAdviceData.image,
          });
        },
        false
      );
      reader.readAsDataURL(file);
    },
  },
};
</script>
